import React from 'react'

import { makeMaterialLayout } from '@main/factories/components/material'
import { NotFound } from '@presentation/pages'

const NotFoundPage = () => <NotFound />

NotFoundPage.getLayout = makeMaterialLayout

export default NotFoundPage
