import { css, Icon, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: ${theme.palette.background.paper};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    .content {
      width: 100%;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > * {
        text-align: center;
      }

      h4 {
        margin-top: 4rem;

        ${theme.breakpoints.up('md')} {
          margin-top: 1rem;
        }
      }

      * + *:not(h4) {
        margin-top: 2.5rem;
      }
    }
  `,
)

export const ErrorIcon = styled(Icon.Page404)(
  ({ theme }) => css`
    width: 250px;
    height: 140px;

    ${theme.breakpoints.up('md')} {
      width: 315px;
      height: 176px;
    }
  `,
)
